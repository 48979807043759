import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { FOOTER_COACHING_LINKS, FOOTER_LINKS, FOOTER_PARTNER_LINKS } from 'constants/general';
import { ONCO_COACH_2025, ONCO_COACH_2025_V2, PARTNERS_PAGE } from 'constants/routes';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useLogin from '../hooks/useLogin';
import LogoutModal from './LogoutModal';
import useFooterLinks from '../hooks/useFooterLinks';
import style from 'assets/scss/components/Footer.module.scss';
import { Col, Container, Row } from 'react-grid-system';
import facebookLogo from 'assets/images/facebook.svg';
import instagramLogo from 'assets/images/instagram.svg';
import linkedinLogo from 'assets/images/linkedin.svg';
import youtubeLogo from 'assets/images/youtube.svg';
import twitterLogo from 'assets/images/twitter.svg';
import visaLogo from 'assets/images/visa-white.svg';
import paypalLogo from 'assets/images/paypal_footer.svg';
import invoiceLogo from 'assets/images/invoice_footer.svg';
import mastercardLogo from 'assets/images/mastercard-white.svg';
import logo from 'assets/images/careandcoach_white.svg';
import { Link, useLocation } from 'react-router-dom';
import useSocialLinks from 'hooks/useSocialLinks';

function Footer() {
  const { t } = useTranslation();
  const { handleLogout, isLogoutModalOpen, handleLogoutModal } = useLogin();
  const { handleFooterLinks } = useFooterLinks();
  const { socialLinks } = useSocialLinks();
  const {
    footerWrapper,
    footerCopyright,
    footerLinks,
    footerLinksItem,
    footerSocials,
    footerSocialsItem,
    footerDetails,
    footerDetailsHead,
    footerDetailsLogo,
    footerDetailsLogocontainer,
    footerDetailsColumn,
  } = convertKeysToCamelCase(style);

  const location = useLocation();

  if (location.pathname === ONCO_COACH_2025 || location.pathname === ONCO_COACH_2025_V2) {
    return <></>;
  }

  return (
    <footer>
      <section className={footerDetails}>
        <Container className={`container-fluid`}>
          <Row>
            <Col md={3} sm={6} xs={12} order={{ md: 1, sm: 3, xs: 1 }} className={footerDetailsColumn}>
              <img src={logo} alt="Care & Coach" />
            </Col>
            <Col md={3} sm={6} xs={12} order={{ md: 2, sm: 1, xs: 2 }} className={footerDetailsColumn}>
              <p className={footerDetailsHead}>{t('header.links.fortbildungen')}</p>
              {FOOTER_COACHING_LINKS.map((link) => (
                <Link to={link.url} key={link.translation}>
                  {t(`header.links.${link.translation}`)}
                </Link>
              ))}
            </Col>
            <Col md={3} sm={6} xs={12} order={{ md: 3, sm: 2, xs: 3 }} className={footerDetailsColumn}>
              <p className={footerDetailsHead}>{t('header.links.partner')}</p>
              {FOOTER_PARTNER_LINKS.map((link) => (
                <Link to={link.url} key={link.translation}>
                  {t(`header.links.${link.translation}`)}
                </Link>
              ))}
              <Link to={PARTNERS_PAGE}>
                <button className="secondary-red">{t('footer.button.partners')}</button>
              </Link>
            </Col>
            <Col md={3} sm={6} xs={12} order={{ md: 4, sm: 4, xs: 4 }} className={footerDetailsColumn}>
              <p className={footerDetailsHead}>{t('header.links.payments')}</p>
              <Row nogutter>
                <Col xs={6} className={footerDetailsLogocontainer}>
                  <img src={visaLogo} alt="Visa" className={footerDetailsLogo} />
                </Col>
                <Col xs={6} className={footerDetailsLogocontainer}>
                  <img src={mastercardLogo} alt="Mastercard" className={footerDetailsLogo} />
                </Col>
                <Col xs={6} className={footerDetailsLogocontainer}>
                  <img src={paypalLogo} alt="paypal" className={footerDetailsLogo} />
                </Col>
                <Col xs={6} className={footerDetailsLogocontainer}>
                  <img src={invoiceLogo} alt="kauf auf rechnung" className={footerDetailsLogo} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={footerWrapper}>
        <Container className={`container-fluid ${footerWrapper}`}>
          <div className={footerCopyright}>
            <span>{t('footer.copyright')}</span>
          </div>
          <div className={footerLinks}>
            {FOOTER_LINKS.map((link) => {
              const { translation, url } = link;

              return (
                <span key={nanoid()} onClick={() => handleFooterLinks(url)} className={footerLinksItem}>
                  {t(`footer.links.${translation}`)}
                </span>
              );
            })}
          </div>
          <div className={footerSocials}>
            {socialLinks.map((link) => (
              <a key={link.id} href={link.url} className={footerSocialsItem}>
                <img
                  src={require(`assets/images/${link.slug}.svg`).default}
                  className={instagramLogo}
                  alt={link.name}
                />
              </a>
            ))}
          </div>
        </Container>
      </section>

      {isLogoutModalOpen && (
        <LogoutModal
          isModalOpen={isLogoutModalOpen}
          handleClose={() => handleLogoutModal(false)}
          handleLogout={handleLogout}
        />
      )}
    </footer>
  );
}

export default Footer;
